import { SelectionChangedEvent } from "ag-grid-community";
import { CurrencyExchangeEntity } from "domain/entity/CurrencyExchange/CurrencyExchangeEntity";
import { INITIAL_CURRENCY_EXCHANGE_COL_DEF, transferRowData } from "presentation/constant/CurrencyExchange/CurrencyExchangeMaintenanceColumnDefinition";
import { CurrencyExchangeMaintenanceConstant } from "presentation/constant/CurrencyExchange/CurrencyExchangeMaintenanceConstant";
import { useCurrencyExchangeMaintenanceVM } from "presentation/hook/CurrencyExchange/useCurrencyExchangeMaintenanceVM";
import { useCurrencyExchangeMaintenanceTracked } from "presentation/store/CurrencyExchange/CurrencyExchangeMaintenanceProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useMemo, useRef } from "react";


const CurrencyExchangeTablePanel: React.FC = () => {
    const [currencyExchangeState] = useCurrencyExchangeMaintenanceTracked();
    const currencyExchangeVM = useCurrencyExchangeMaintenanceVM();
    const gridRef: any = useRef(null);

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();

        currencyExchangeVM.updateSelectedRows(selectedRows);
    }, [currencyExchangeVM])

    const handleAddClick = useCallback(() => {
        currencyExchangeVM.onAdd();
    }, [currencyExchangeVM])

    const handleRowDoubleClick = useCallback((entity: CurrencyExchangeEntity) => {
        currencyExchangeVM.onEdit(entity);
    }, [currencyExchangeVM])

    const memoTable = useMemo(() => {
        return (
            <NbisTable
                id='currency-exchange-table'
                headerLabel={CurrencyExchangeMaintenanceConstant.TITLE}
                isNewColumnSetting={true}
                columns={INITIAL_CURRENCY_EXCHANGE_COL_DEF}
                data={transferRowData(currencyExchangeState.tableData ?? [])}
                showPaginator={false}
                editable={false}
                showAddIcon={true}
                onAddClick={handleAddClick}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                selectionMode={false}
                isRowHighligted={true}
                handleSelectionChanged={handleSelectionChange}
                onRowDoubleClick={(e: any, entity: CurrencyExchangeEntity) => handleRowDoubleClick(entity)}
                gridHeight="customHeight"
                customHeight="calc(100vh - 75px)"
                ref={gridRef}
            />
        );
    }, [currencyExchangeState.tableData, handleAddClick, handleSelectionChange, handleRowDoubleClick])

    return <><TableWrapper>
        {memoTable}
    </TableWrapper>
    </>;
}

export default memo(CurrencyExchangeTablePanel);
