import type { CustomCellRendererProps } from "ag-grid-react";
import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { CurrencyExchangeMaintenanceConstant } from "./CurrencyExchangeMaintenanceConstant";
import { DECIMAL_4 } from "presentation/utils/numberUtil";
let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];
let numberFieldList: string[] = [];
const SCREEN_CONSTANT = CurrencyExchangeMaintenanceConstant;
export const INITIAL_CURRENCY_EXCHANGE_COL_DEF: any[] = [
    {
        headerName: SCREEN_CONSTANT.FROM_CRURRENCY,
        field: "fromCurrency",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
        //pinned: "left",
        //checkboxSelection: true,
        //headerCheckboxSelection: true,

    },
    {
        headerName: SCREEN_CONSTANT.TO_CRURRENCY,
        field: "toCurrency",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: SCREEN_CONSTANT.EFF_DATE,
        field: "effectiveDate",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
        dataType: "date"
    },
    {
        headerName: SCREEN_CONSTANT.EXCHANGE_RATE,
        field: "exchangeRate",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        dataType: DECIMAL_4,
        width: 200,
    },
    {
        headerName: SCREEN_CONSTANT.BANK_NO,
        field: "bankNo",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
]?.map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};
    //cellRenderers['dtlState'] = cellRenderWithColorAndCircle;
    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers, numberFieldList);
}
);

export const transferRowData = (data: any[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};
    //externalFnctions['dtlState'] = calculateStateColor;
    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions, numberFieldList);
}
